import React, { useEffect, useState, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Dialog, Transition } from "@headlessui/react";
import api from "../api";
import Sidebar from "../components/Sidebar";
import DimensionCard from "../components/DimensionCard";
import ImprovementCard from "../components/ImprovementCard";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import LearnCard from "../components/LearnCard";
import CustomProgressBar from "../components/ProgressBar";
import Modal from "../components/Modal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LearnPage() {
  const [error_message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(0);
  const [tag, setTag] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [articles, setLearnArticles] = useState(undefined);
  const [categories, setCategories] = useState(undefined);
  const [tags, setTags] = useState(undefined);
  const navigate = useNavigate();
  const today = new Date();

  const options = [{ name: "Compromiso" }];

  useEffect(() => {
    api.users.getMe().then((userData) => {
      if (userData.company)
        api.categories.getMulti().then((results) => {
          results.unshift({ id: 0, name: "Todos" });
          setCategories(results);
          api.tags.getMulti().then((resultsB) => {
            resultsB.unshift({ id: 0, name: "Todos" });
            setTags(resultsB);
            api.learnArticles
              .getMulti(
                null,
                null,
                null,
                null,
                tag !== 0
                  ? { category: category ? category : "", tags: tag }
                  : { category: category ? category : "" }
              )
              .then((response) => {
                setLearnArticles(response);
                setLoading(false);
              })
              .catch((error) => setErrorMessage(error));
          });
        });
      else {
        setLoading(false);
        setShowModal(true);
      }
    });
  }, [category, tag]);

  return (
    <div className="min-h-screen h-full w-full flex select-none bg-gray-50">
      <Sidebar
        currentScreen={"Aprender"}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className="md:pl-64 min-h-screen w-full flex flex-col pb-10">
        {loading ? (
          <div className="w-16 h-16 mx-auto my-auto border-b-2 border-gray-500 rounded-full animate-spin" />
        ) : (
          <>
            <CustomProgressBar />
            <div className="mt-6 px-6 flex flex-col">
              <p className="text-2xl font-bold">Aprender</p>
              <p className="text-[#5E5E5E] text-sm mt-2">
                Te ofrecemos materiales y recursos innovadores sobre las
                políticas de cuidado. Aprendé más sobre licencias, horarios
                flexibles (flexitime) y muchos otros temas. ¡Aprendé más sobre
                buenas prácticas de cuidado en las empresas!
              </p>
              <div className="border border-[#D9D9D9] w-full mt-3" />
            </div>
            <div className="mt-10 px-6 flex flex-col gap-3">
              <div className="flex gap-3 divide-[#F27F50] divide-x">
                <p className="text-[#F27F50] font-medium">Filtrar por</p>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-fit ml-3 justify-center gap-x-1.5 rounded-[52px] bg-[#F27F50] text-white px-5 py-2 text-sm ring-0 outline-0">
                      {category !== 0
                        ? categories.find((element) => element.id === category)
                            .name
                        : "Categorías"}
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-3 z-10 mt-2 w-56 h-48 overflow-scroll origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div>
                        {categories &&
                          categories.map((element) => (
                            <Menu.Item id={"category " + element.id}>
                              {({ active }) => (
                                <button
                                  onClick={() => setCategory(element.id)}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm w-full text-center"
                                  )}
                                >
                                  {element.name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-fit ml-3 justify-center gap-x-1.5 rounded-[52px] bg-[#F27F50] text-white px-5 py-2 text-sm ring-0 outline-0">
                      {tag !== 0
                        ? tags.find((element) => element.id === tag).name
                        : "Tags"}
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-3 z-10 mt-2 w-56 h-48 overflow-scroll origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div>
                        {tags &&
                          tags.map((element) => (
                            <Menu.Item id={"tag" + element.id}>
                              {({ active }) => (
                                <button
                                  onClick={() => setTag(element.id)}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm w-full text-center"
                                  )}
                                >
                                  {element.name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <p className="text-lg font-semibold">En base a tu diagnóstico</p>
              <div className="grid grid-cols-3 gap-6">
                {articles &&
                  articles.map((element) => (
                    <LearnCard
                      title={element.title}
                      image={element.image}
                      idArticle={element.id}
                      category={
                        categories.find(
                          (category) => category.id === element.category
                        ).name
                      }
                      subtitle={element.subtitle}
                      tags={element.tags}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
